import { GenericSearchResult, ISearchParams } from './types'
import {
  Location,
  Pedestal,
  SourceTest,
  User,
  Session,
  Account,
  Receipt,
  UserExistsInfo,
  Facility,
  SignedUrl,
  Vehicle,
  Alarm,
  PedestalType,
  Source,
  SessionRequest,
  ReceiptItem,
} from './api-types'
import axios, { AxiosError, AxiosResponse } from 'axios'

import { ILocationForm } from './pages/superadmin/location-new/SuperadminNewLocationPage'
import { IPedestalForm } from './pages/superadmin/pedestal-new/SuperadminPedestalForm'
import { ISourceTestForm } from './pages/superadmin/pedestal-test/SuperadminPedestalTestingTab'
import { IUserForm } from './pages/common/general/UserDrawer'
import {
  CreatePeriodicalReceiptParams,
  CreatePostpaidReceiptParams,
  CreatePrepaidReceiptParams,
  unassignPedestal,
  UpdateAccountPaymentInfo,
  UpdateAccountSessionPermissions,
  UpdateCustomerInfo,
  UpdatePedestalParams,
} from './input-param-types'
import { config } from './config'
import { CreateCustomerForm } from './pages/admin/add-new-customer/AddNewCustomer'
import { adminRoutes } from './pages/common/general/route-names'
import { ReportSearchParams } from './pages/common/reports/report-types/report-search-params-types'
import { ReportType } from './pages/common/reports/report-types/report-enums'
import { SocketReport } from './pages/common/reports/report-types/report-types'
import { EditEmployeeForm } from './pages/admin/customer-view/EditEmployeeDrawer'

export const axiosInstance = axios.create({
  baseURL: `${config.BACKEND_URL}`,
  withCredentials: false,
})

axiosInstance.interceptors.request.use((req) => {
  const token =
    window.localStorage.getItem('token') ||
    window.sessionStorage.getItem('token')
  if (token) {
    // @ts-ignore
    req.headers['Authorization'] = `Bearer ${token}`
  }
  return req
})

//TODO: extend with other response status codes
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (err: AxiosError) => {
    const status = err.response?.status
    switch (status) {
      case 403: {
        return (window.location.href = adminRoutes.DASHBOARD)
      }
      default: {
        return Promise.reject(err)
      }
    }
  },
)

class HTTP {
  login = async (data: { username: string; password: string }) => {
    const response = await axiosInstance.post<{ token: string }>(
      `/admin/auth/signin`,
      data,
    )
    return response.data
  }
  forgotPassword = async (data: { email: string }) => {
    const response = await axiosInstance.post<{ message: string }>(
      `/auth/forgot-password`,
      {
        ...data,
        platform: 'ADMIN',
      },
    )
    return response.data
  }
  resetPassword = async (data: {
    password: string
    passwordResetToken: string
  }) => {
    const response = await axiosInstance.post<{ message: string }>(
      `/auth/reset-password`,
      data,
    )
    return response.data
  }
  resetCustomerPassword = async (data: { id: number }) => {
    const response = await axiosInstance.post<{ message: string }>(
      `/admin/users/reset-password`,
      data,
    )
    return response.data
  }
  me = async () => {
    const response = await axiosInstance.get<User>(`/users/me`)
    return response.data
  }
  getCurrentLocation = async () => {
    const response = await axiosInstance.get<Location>(`/admin/location`)
    return response.data
  }
  updateAdminLocation = async (location: Partial<Location>) => {
    const response = await axiosInstance.put<Location>(
      `/admin/location`,
      location,
    )
    return response.data
  }
  searchLocations = async (params: ISearchParams) => {
    const response = await axiosInstance.get<GenericSearchResult<Location>>(
      `/superadmin/locations`,
      { params },
    )
    return response.data
  }
  searchPedestalSessions = async (
    params: ISearchParams,
    pedestalId: string,
  ) => {
    const response = await axiosInstance.get<GenericSearchResult<any>>(
      `/admin/sessions/pedestal/${pedestalId}`,
      { params },
    )
    return response.data
  }
  searchLocationSessions = async (params: ISearchParams) => {
    const response = await axiosInstance.get<GenericSearchResult<any>>(
      `/admin/sessions`,
      { params },
    )
    return response.data
  }
  getSession = async (id: string) => {
    const response = await axiosInstance.get<Session>(`admin/sessions/${id}`)
    return response.data
  }
  startSession = async (
    sourceId: number,
    accountId: number,
    vehicleId: number,
  ) => {
    await axiosInstance.post(`/admin/sessions/request-session-start`, {
      sourceId,
      accountId,
      vehicleId,
    })
  }
  requestSessionStopById = async (sessionId: number) => {
    const response = await axiosInstance.post<
      number,
      AxiosResponse<SessionRequest>
    >(`/admin/sessions/request-session-stop`, {
      sessionId,
    })
    return response.data
  }
  requestSessionsStopByAccount = async (accountId: number) => {
    const response = await axiosInstance.post<
      number,
      AxiosResponse<SessionRequest[]>
    >(`/admin/sessions/request-stop-by-account`, {
      accountId,
    })
    return response.data
  }
  getSessionRequests = async (ids: number[] | undefined) => {
    const response = await axiosInstance.get<
      number[],
      AxiosResponse<SessionRequest[]>
    >(`/admin/sessions/get-requests`, {
      params: { sessionRequestIds: ids },
    })
    return response.data
  }

  getLatestReceiptForAccount = async (accountId: number | undefined) => {
    const response = await axiosInstance.get<Receipt>(
      `/admin/receipts/get-latest-for-account/${accountId}`,
    )
    return response.data
  }

  getLocation = async (id: number | string) => {
    const response = await axiosInstance.get<GenericSearchResult<Location>>(
      `/superadmin/locations/${id}`,
    )
    return response.data
  }
  getEmployees = async (locationId: string) => {
    const response = await axiosInstance.get(
      `/superadmin/locations/employees/${locationId}`,
    )
    return response.data
  }
  searchUsers = async (params: ISearchParams) => {
    const response = await axiosInstance.get<GenericSearchResult<User>>(
      `/admin/users`,
      { params },
    )
    return response.data
  }
  searchSuperAdminUsers = async (params: ISearchParams) => {
    const response = await axiosInstance.get<GenericSearchResult<User>>(
      `/admin/users/superadmins`,
      { params },
    )
    return response.data
  }
  searchAdminsAndSuperAdminUsers = async (params: ISearchParams) => {
    const response = await axiosInstance.get<GenericSearchResult<User>>(
      `/admin/users/admins-and-superadmins`,
      { params },
    )
    return response.data
  }
  facilities = async () => {
    const response = await axiosInstance.get<Facility[]>(`/admin/facilities`)
    return response.data
  }
  searchPedestals = async (params: ISearchParams) => {
    const response = await axiosInstance.get<GenericSearchResult<Pedestal>>(
      `/superadmin/pedestals`,
      { params },
    )
    return response.data
  }
  createLocation = async (data: ILocationForm) => {
    await axiosInstance.post(`/superadmin/locations`, data)
  }
  updateLocation = async (id: number, data: ILocationForm) => {
    await axiosInstance.patch(`/superadmin/locations/${id}`, data)
  }
  createPedestals = async (data: IPedestalForm) => {
    return await axiosInstance.post<Pedestal[]>(
      `/superadmin/pedestals/bulk`,
      data.pedestals.map((p) => {
        return {
          identifier: p.identifier,
          locationId: p.location.value,
        }
      }),
    )
  }

  createSocketTest = async (data: ISourceTestForm) => {
    return axiosInstance.post<SourceTest>(`/admin/source-test/socket`, data)
  }

  createValveTest = async (data: ISourceTestForm) => {
    return axiosInstance.post<SourceTest>(`/admin/source-test/valve`, data)
  }

  createSuperAdmin = async (data: IUserForm) => {
    return axiosInstance.post<IUserForm>(`/admin/users/superadmin`, data)
  }

  createAdminOrSuperAdmin = async (data: IUserForm) => {
    return axiosInstance.post<IUserForm>(
      `/admin/users/admin-and-superadmin`,
      data,
    )
  }

  updateSuperAdmin = async (data: IUserForm) => {
    return axiosInstance.patch<User>(`/admin/users/superadmin`, data)
  }

  useDeleteUser = async (id: number) => {
    return axiosInstance.delete<void>(`/admin/users/superadmin/${id}`)
  }

  getSuperAdminPedestal = async (identifier: string): Promise<Pedestal> => {
    const response = await axiosInstance.get<Pedestal>(
      `/superadmin/pedestals/${identifier}`,
    )
    return response.data
  }

  getAdminPedestal = async (identifier: string): Promise<Pedestal> => {
    const response = await axiosInstance.get<Pedestal>(
      `/admin/pedestals/${identifier}`,
    )
    return response.data
  }

  updatePedestalConfiguration = async (
    id: number,
    params: UpdatePedestalParams,
  ) => {
    return await axiosInstance.patch<UpdatePedestalParams, AxiosResponse<any>>(
      `/superadmin/pedestals/configuration/${id}`,
      params,
    )
  }

  uploadPedestalConfiguration = async (id: number) => {
    return await axiosInstance.post<any, AxiosResponse<any>>(
      `/superadmin/pedestals/configuration/upload/${id}`,
    )
  }

  // Make more strict, its not a partial pedestal actually - check backend
  updatePedestal = async (id: number, params: Partial<Pedestal>) => {
    return await axiosInstance.patch<UpdatePedestalParams, AxiosResponse<any>>(
      `/superadmin/pedestals/${id}`,
      params,
    )
  }

  updateValveState = async (id: number, state: string) => {
    return await axiosInstance.patch<any, AxiosResponse<any>>(
      `/source/valve/${id}/set/${state}`,
    )
  }
  updateSocketState = async (id: number, state: string) => {
    return await axiosInstance.patch<any, AxiosResponse<any>>(
      `/source/socket/${id}/set/${state}`,
    )
  }
  updateLightState = async (identifier: string, state: string) => {
    return await axiosInstance.patch<any, AxiosResponse<any>>(
      `/superadmin/pedestals/light/${identifier}/set/${state}`,
    )
  }
  updateHeatState = async (identifier: string, state: string) => {
    return await axiosInstance.patch<any, AxiosResponse<any>>(
      `/superadmin/pedestals/heat/${identifier}/set/${state}`,
    )
  }
  searchPedestalLogs = async (params: ISearchParams) => {
    const response = await axiosInstance.get<GenericSearchResult<Pedestal>>(
      `/admin/pedestal-logs`,
      { params },
    )
    return response.data
  }
  searchAdminPedestals = async (params: ISearchParams) => {
    const response = await axiosInstance.get<GenericSearchResult<Pedestal>>(
      `/admin/pedestals`,
      { params },
    )
    return response.data
  }
  getDashboardPedestals = async () => {
    const response = await axiosInstance.get<Pedestal[]>(
      `/admin/pedestals/location-dashboard`,
    )
    return response.data
  }
  getSignedUrl = async (name: string) => {
    const response = await axiosInstance.post<SignedUrl>(`/files/signed-url`, {
      name,
    })
    return response.data
  }

  searchAdminClients = async (params: ISearchParams) => {
    const response = await axiosInstance.get<GenericSearchResult<Account>>(
      `/admin/accounts`,
      { params },
    )
    return response.data
  }

  searchAdminAlarms = async (params: ISearchParams) => {
    const response = await axiosInstance.get<GenericSearchResult<Alarm>>(
      `/admin/alarms`,
      { params },
    )
    return response.data
  }

  useAddPrepaidPayment = async ({
    id,
    amount,
  }: {
    id: number
    amount: number
  }) => {
    return axiosInstance.post<Account>(`/admin/accounts/${id}/add-funds`, {
      amount,
    })
  }

  searchAdminAccountSessions = async (
    accountId: number,
    params: ISearchParams,
  ) => {
    const response = await axiosInstance.get<GenericSearchResult<Session>>(
      `/admin/sessions/account/${accountId}`,
      { params },
    )
    return response.data
  }

  searchAdminAccountReceipts = async (
    accountId: number,
    params: ISearchParams,
  ) => {
    const response = await axiosInstance.get<GenericSearchResult<Receipt>>(
      `/admin/receipts/account/${accountId}`,
      { params },
    )
    return response.data
  }

  getAdminReceipt = async (transactionId: string | number) => {
    const response = await axiosInstance.get<Receipt>(
      `/admin/receipts/${transactionId}`,
    )
    return response.data
  }
  updateReceiptStatus = async (transactionId: string) => {
    await axiosInstance.patch(`/admin/receipts/change-status/${transactionId}`)
  }
  createPostpaidReceipt = async (params: CreatePostpaidReceiptParams) => {
    const response = await axiosInstance.post(
      `/admin/receipts/create/postpaid`,
      params,
    )
    return response.data
  }

  createPeriodicalReceipt = async (params: CreatePeriodicalReceiptParams) => {
    const response = await axiosInstance.post(
      `/admin/receipts/create/periodical`,
      params,
    )
    return response.data
  }

  createPrepaidReceipt = async (params: CreatePrepaidReceiptParams) => {
    const response = await axiosInstance.post(
      `/admin/receipts/create/prepaid`,
      params,
    )
    return response.data
  }

  getReceiptPreview = async (accountId: number, periodEndDate: Date) => {
    const response = await axiosInstance.get<ReceiptItem[]>(
      `/admin/receipts/preview/${accountId}`,
      { params: { periodEndDate } },
    )
    return response.data
  }

  adminClient = async (id: string) => {
    const response = await axiosInstance.get<Account>(`/admin/accounts/${id}`)
    return response.data
  }
  checkIfUserExists = async (email: string) => {
    const response = await axiosInstance.post<UserExistsInfo>(
      `/admin/users/check-if-exists`,
      { email },
    )
    return response.data
  }
  addUserAccount = async (data: CreateCustomerForm) => {
    const response = await axiosInstance.post<User, AxiosResponse<Account>>(
      `/admin/users/add-account`,
      data,
    )
    return response.data
  }
  updateAccountPaymentInfo = async (data: UpdateAccountPaymentInfo) => {
    const response = await axiosInstance.patch<Account>(
      `/admin/accounts/payment-info`,
      data,
    )
    return response.data
  }
  updateAccountSessionPermissions = async (
    data: UpdateAccountSessionPermissions,
  ) => {
    const response = await axiosInstance.patch<Account>(
      `/admin/accounts/session-permissions`,
      data,
    )
    return response.data
  }
  updateCustomerInfo = async (data: UpdateCustomerInfo) => {
    const response = await axiosInstance.patch<Account>(
      `/admin/users/customer-info`,
      data,
    )
    return response.data
  }
  updateCustomerPassword = async (data: { password: string; id: number }) => {
    const response = await axiosInstance.patch<Account>(
      `/admin/users/customer-password`,
      data,
    )
    return response.data
  }
  deleteCustomer = async (id: number) => {
    const response = await axiosInstance.delete<Account>(
      `/admin/users/customer-delete/${id}`,
    )
    return response.data
  }
  createVehicle = async (data: UpdateCustomerInfo) => {
    const response = await axiosInstance.post<Vehicle>(`/admin/vehicles`, data)
    return response.data
  }
  editVehicle = async (data: UpdateCustomerInfo) => {
    const response = await axiosInstance.patch<Vehicle>(`/admin/vehicles`, data)
    return response.data
  }
  deleteVehicle = async (id: number) => {
    const response = await axiosInstance.delete<Vehicle>(
      `/admin/vehicles/delete/${id}`,
    )
    return response.data
  }
  createEmployee = async (data: EditEmployeeForm) => {
    const response = await axiosInstance.post<User>(
      `/admin/users/employee/create`,
      data,
    )
    return response.data
  }
  editEmployee = async (data: EditEmployeeForm) => {
    const response = await axiosInstance.patch<User>(
      `/admin/users/employee/edit`,
      data,
    )
    return response.data
  }
  deleteEmployee = async (id: number) => {
    const response = await axiosInstance.delete<User>(
      `/admin/users/employee/delete/${id}`,
    )
    return response.data
  }
  getAllAssignablePedestals = async () => {
    const response = await axiosInstance.get<Pedestal[]>(
      `admin/pedestals/all-assignable`,
    )

    return response.data
  }
  assignPedestal = async (data: { charterId: number; pedestalId: number }) => {
    const response = await axiosInstance.patch<Pedestal>(
      `/admin/pedestals/${data.pedestalId}/assign-charter`,
      { charterId: data.charterId },
    )
    return response.data
  }
  unassignPedestal = async ({ pedestalId, charterId }: unassignPedestal) => {
    const response = await axiosInstance.patch<Pedestal>(
      `/admin/pedestals/${pedestalId}/unassign-charter/${charterId}`,
    )
    return response.data
  }
  searchVehicles = async (params: ISearchParams) => {
    const response = await axiosInstance.get<GenericSearchResult<Vehicle>>(
      `admin/vehicles`,
      {
        params,
      },
    )
    return response.data
  }
  searchLocationAdminUsers = async (params: ISearchParams) => {
    const response = await axiosInstance.get<GenericSearchResult<User>>(
      `/admin/users/location`,
      {
        params,
      },
    )
    return response.data
  }
  createLocationUser = async (data: any) => {
    const response = await axiosInstance.post<User>(
      `/admin/users/location/create`,
      data,
    )
    return response.data
  }

  editLocationUser = async (data: any) => {
    const response = await axiosInstance.patch<User>(
      `/admin/users/location/edit`,
      data,
    )
    return response.data
  }

  deleteLocationUser = async (id: number) => {
    return axiosInstance.delete<void>(`/admin/users/location/delete/${id}`)
  }

  updateLocationPaymentInfo = async (data: any) => {
    const response = await axiosInstance.patch<User>(
      `/admin/location/update-payment-info`,
      data,
    )
    return response.data
  }

  getAllPedestals = async () => {
    const response = await axiosInstance.get<Pedestal[]>(`/admin/pedestals/all`)
    return response.data
  }

  getAllPedestalTypes = async () => {
    const response = await axiosInstance.get<PedestalType[]>(
      `/admin/pedestals/all-types`,
    )
    return response.data
  }

  getAllSourcesByPedestalId = async (pedestalId?: number) => {
    const response = await axiosInstance.get<Source[]>(
      '/admin/source/by-pedestal',
      {
        params: {
          pedestalId,
        },
      },
    )
    return response.data
  }

  getAllUsers = async () => {
    const response = await axiosInstance.get<User[]>(`/admin/users/all`)
    return response.data
  }

  getAllVehicles = async () => {
    const response = await axiosInstance.get<Vehicle[]>(`/admin/vehicles/all`)
    return response.data
  }

  searchTransactions = async (params: ISearchParams) => {
    const response = await axiosInstance.get<GenericSearchResult<Receipt>>(
      `/admin/receipts`,
      { params },
    )
    return response.data
  }

  searchSourcesReport = async (params: ISearchParams) => {
    const response = await axiosInstance.get<GenericSearchResult<SocketReport>>(
      `/admin/reports/sources`,
      { params },
    )
    return response.data
  }

  searchReport = async <TParams extends ReportSearchParams>(
    reportType: ReportType,
    params: TParams,
  ) => {
    const response = await axiosInstance.get(`/admin/reports/${reportType}`, {
      params,
    })

    return response.data
  }

  getReportTable = async <TParams extends ReportSearchParams>(
    reportType: ReportType,
    params: TParams,
  ) => {
    //TODO: For now, all have transactions table
    const response = await axiosInstance.get(
      // `/admin/reports/${reportType}/table`,
      `/admin/reports/transactions/table`,
      {
        params,
      },
    )

    return response.data
  }

  getReportExportData = async <
    TParams extends Omit<ReportSearchParams, 'granularity'>,
  >(
    reportType: ReportType,
    params: TParams,
  ) => {
    const response = await axiosInstance.get(
      `admin/reports/${reportType}/export`,
      {
        params,
      },
    )

    return response.data
  }

  getAdminTransactions = async (params: ISearchParams) => {
    const response = await axiosInstance.get(`/admin/receipts/transactions`, {
      params,
    })
    return response.data
  }
}

export const http = new HTTP()
