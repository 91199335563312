import { GenericSearchResult, ISearchParams } from './types'
import {
  Account,
  Alarm,
  Location,
  Pedestal,
  PedestalLog,
  PedestalType,
  Receipt,
  ReceiptItem,
  Session,
  Source,
  User,
  Vehicle,
} from './api-types'

import { http } from './http'
import { transformQueryResponseData } from './utils'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  UpdateAccountPaymentInfo,
  UpdateAccountSessionPermissions,
  UpdateCustomerInfo,
} from './input-param-types'
import { AxiosError } from 'axios'
import { ReportType } from './pages/common/reports/report-types/report-enums'
import { ReportSearchParams } from './pages/common/reports/report-types/report-search-params-types'
import {
  Report,
  ReportConsumptionTableData,
  SocketReport,
  Transaction,
} from './pages/common/reports/report-types/report-types'

export const queries = {
  loggedInUser: 'logged_in_user',
  locations: 'locations',
  users: 'users',
  employees: 'employees',
  superAdminUsers: 'super_admin_users',
  facilities: 'facilities',
  pedestals: 'pedestals',
  dashboard: 'dashboard',
  clients: 'clients',
  alarms: 'alarms',
  accountSessions: 'account_sessions',
  receipt: 'receipt',
  latestReceipt: 'latest_receipt',
  pedestal: 'pedestal',
  pedestalLogs: 'pedestal_logs',
  session: 'session',
  customers: 'customers',
  currentLocation: 'current_location',
  locationAdminUsers: 'location_admin_users',
  allPedestals: 'all_pedestals',
  allPedestalTypes: 'all_pedestal_types',
  allSourcesByPedestalId: 'all_sources_by_pedestal_id',
  allUsers: 'all_users',
  allVehicles: 'all_vehicles',
  sessionsReport: 'sessions_report',
  manualSessionsReport: 'manual_sessions_report',
  receipts: 'receipts',
  receiptPreview: 'receipt_preview',
  sessionRequestids: 'session_request_ids',
  assignablePedestals: 'assignable_pedestals',
  adminTransactions: 'admin_transactions',
}

export function useCurrentUser() {
  return useQuery<User>(queries.loggedInUser, http.me, {})
}

export function useCurrentLocation() {
  return useQuery<Location>(queries.currentLocation, http.getCurrentLocation)
}

export function useLocations(searchParams: ISearchParams, enabled = true) {
  return transformQueryResponseData<GenericSearchResult<Location>>(
    useQuery(
      [queries.locations, searchParams],
      () => http.searchLocations(searchParams),
      { enabled: enabled },
    ),
  )
}

export function useSessions(searchParams: ISearchParams, pedestalId: string) {
  return transformQueryResponseData<GenericSearchResult<Session>>(
    useQuery([queries.session, searchParams, pedestalId], () =>
      http.searchPedestalSessions(searchParams, pedestalId),
    ),
  )
}

export function useLocationSessions(searchParams: ISearchParams) {
  return transformQueryResponseData<GenericSearchResult<Session>>(
    useQuery([queries.session, searchParams], () =>
      http.searchLocationSessions(searchParams),
    ),
  )
}

export function useSession(id: string, enabled = true) {
  return useQuery([queries.session, id], () => http.getSession(id), {
    enabled,
    refetchInterval: 3000,
    refetchOnWindowFocus: true,
  })
}

export function useLocation(id: string | number) {
  return transformQueryResponseData<Location>(
    useQuery([queries.locations, id], () => http.getLocation(id)),
  )
}

export function useEmployees(id: string) {
  return useQuery([queries.employees, id], () => http.getEmployees(id))
}

export function useUsers(searchParams: ISearchParams) {
  return transformQueryResponseData<GenericSearchResult<User>>(
    useQuery([queries.users, searchParams], () =>
      http.searchUsers(searchParams),
    ),
  )
}

export function useSuperAdminUsers(searchParams: ISearchParams) {
  return transformQueryResponseData<GenericSearchResult<User>>(
    useQuery([queries.superAdminUsers, searchParams], () =>
      http.searchSuperAdminUsers(searchParams),
    ),
  )
}

export function useAdminsAndSuperAdminUsers(searchParams: ISearchParams) {
  return transformQueryResponseData<GenericSearchResult<User>>(
    useQuery([queries.superAdminUsers, searchParams], () =>
      http.searchAdminsAndSuperAdminUsers(searchParams),
    ),
  )
}

export function useFacilities() {
  return useQuery([queries.facilities], () => http.facilities())
}

export function usePedestals(searchParams: ISearchParams) {
  return transformQueryResponseData<GenericSearchResult<Pedestal>>(
    useQuery([queries.pedestals, searchParams], () =>
      http.searchPedestals(searchParams),
    ),
  )
}

export function useAdminClients(searchParams: ISearchParams) {
  return transformQueryResponseData<GenericSearchResult<Account>>(
    useQuery([queries.clients, searchParams], () =>
      http.searchAdminClients(searchParams),
    ),
  )
}

export function useAdminAlarms(searchParams: any) {
  return transformQueryResponseData<GenericSearchResult<Alarm>>(
    useQuery([queries.alarms, searchParams], () =>
      http.searchAdminAlarms(searchParams),
    ),
  )
}

export function useAdminAccountSessions(
  accountId: number,
  searchParams: ISearchParams,
) {
  return transformQueryResponseData<GenericSearchResult<Session>>(
    useQuery([queries.accountSessions, accountId, searchParams], () =>
      http.searchAdminAccountSessions(accountId, searchParams),
    ),
  )
}

export function useAdminAccountReceipts(
  accountId: number,
  searchParams: ISearchParams,
) {
  return transformQueryResponseData<GenericSearchResult<Receipt>>(
    useQuery([queries.receipt, accountId, searchParams], () =>
      http.searchAdminAccountReceipts(accountId, searchParams),
    ),
  )
}

export function useAdminAccountReceipt(transactionId: string | number) {
  return transformQueryResponseData<Receipt>(
    useQuery([queries.receipt, transactionId], () =>
      http.getAdminReceipt(transactionId),
    ),
  )
}

export function useUpdateReceiptStatus(transactionId: string) {
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation<void>(
    () => http.updateReceiptStatus(transactionId),
    {
      onSuccess: async () => {
        await queryClient.resetQueries([queries.receipt, transactionId])
      },
      onError: (error) => {
        throw error
      },
    },
  )
  return mutateAsync
}

export function useCreatePostpaidReceipt() {
  const { mutateAsync } = useMutation(http.createPostpaidReceipt, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}

export function useCreatePeriodicalReceipt() {
  const { mutateAsync } = useMutation(http.createPeriodicalReceipt, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}

export function useCreatePrepaidReceipt() {
  const { mutateAsync } = useMutation(http.createPrepaidReceipt, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}

export function useReceiptPreview(accountId: number, periodEndDate: Date) {
  return useQuery<ReceiptItem[], AxiosError>(
    [queries.receiptPreview, periodEndDate],
    () => http.getReceiptPreview(accountId, periodEndDate),
  )
}

export function useAdminClient(id: string) {
  return useQuery<Account>([queries.clients, id], () => http.adminClient(id))
}

export function useAdminPedestals(searchParams: ISearchParams) {
  return transformQueryResponseData<GenericSearchResult<Pedestal>>(
    useQuery([queries.pedestals, searchParams], () =>
      http.searchAdminPedestals(searchParams),
    ),
  )
}

export function useDashboardPedestals() {
  return useQuery(queries.dashboard, () => http.getDashboardPedestals())
}

export function useAdminPedestal(identifier: string) {
  return useQuery<Pedestal>(
    [queries.pedestal, identifier],
    () => http.getAdminPedestal(identifier),
    {
      refetchInterval: 3000,
      refetchOnWindowFocus: true,
    },
  )
}

export function useSuperAdminPedestal(identifier: string) {
  return useQuery<Pedestal>(
    [queries.pedestal, identifier],
    () => http.getSuperAdminPedestal(identifier),
    {
      refetchInterval: 3000,
      refetchOnWindowFocus: true,
    },
  )
}

export function usePedestalLogs(
  searchParams: ISearchParams & { pedestalId: number },
) {
  return transformQueryResponseData<GenericSearchResult<PedestalLog>>(
    useQuery([queries.pedestalLogs, searchParams], () =>
      http.searchPedestalLogs(searchParams),
    ),
  )
}

//not used anywhere,superadmin creation handled by useCreateAdminOrSuperAdmin
export function useCreateSuperAdmin() {
  const { mutateAsync } = useMutation(http.createSuperAdmin, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}

export function useCreateAdminOrSuperAdmin() {
  const { mutateAsync } = useMutation(http.createAdminOrSuperAdmin, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}

export function useUpdateSuperAdmin() {
  const { mutateAsync } = useMutation(http.updateSuperAdmin, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}
export function useUpdateAccountPaymentInfo() {
  const { mutateAsync } = useMutation<Account, any, UpdateAccountPaymentInfo>(
    http.updateAccountPaymentInfo,
    {
      onError: (error) => {
        throw error
      },
    },
  )
  return mutateAsync
}
export function updateAccountSessionPermissions() {
  const { mutateAsync } = useMutation<
    Account,
    any,
    UpdateAccountSessionPermissions
  >(http.updateAccountSessionPermissions, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}

export function useRequestSessionsStopByAccount() {
  const { mutateAsync } = useMutation(http.requestSessionsStopByAccount, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}

export function useRequestSessionStopById() {
  const { mutateAsync } = useMutation(http.requestSessionStopById, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}

export function useGetSessionRequests(
  sessionRequestIds: number[] | undefined,
  enabled: boolean,
) {
  return useQuery(
    queries.sessionRequestids,
    () => http.getSessionRequests(sessionRequestIds),
    { enabled, refetchInterval: 1000 },
  )
}

export function useGetLatestReceiptForAccount(
  accountId: number | undefined,
  enabled: boolean,
) {
  return useQuery(
    queries.latestReceipt,
    () => http.getLatestReceiptForAccount(accountId),
    { enabled },
  )
}

export function useUpdateCustomerInfo() {
  const { mutateAsync } = useMutation<Account, any, UpdateCustomerInfo>(
    http.updateCustomerInfo,
    {
      onError: (error) => {
        throw error
      },
    },
  )
  return mutateAsync
}
export function useUpdateCustomerPassword() {
  const { mutateAsync } = useMutation(http.updateCustomerPassword, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}
export function useDeleteCustomer() {
  const { mutateAsync } = useMutation(http.deleteCustomer, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}
export function useDeleteUser() {
  const { mutateAsync } = useMutation(http.useDeleteUser, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}
export function useCreateVehicle() {
  const { mutateAsync } = useMutation(http.createVehicle, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}
export function useEditVehicle() {
  const { mutateAsync } = useMutation(http.editVehicle, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}
export function useDeleteVehicle() {
  const { mutateAsync } = useMutation(http.deleteVehicle, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}

export function useCreateEmployee() {
  const { mutateAsync } = useMutation(http.createEmployee, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}
export function useEditEmployee() {
  const { mutateAsync } = useMutation(http.editEmployee, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}
export function useDeleteEmployee() {
  const { mutateAsync } = useMutation(http.deleteEmployee, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}

export function useGetAllAssignablePedestals() {
  return useQuery<Pedestal[]>(
    queries.assignablePedestals,
    http.getAllAssignablePedestals,
  )
}

export function useAssignPedestal() {
  const { mutateAsync } = useMutation(http.assignPedestal, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}

export function useUnassignPedestal() {
  const { mutateAsync } = useMutation(http.unassignPedestal, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}

export function useAddPrepaidPayment() {
  const { mutateAsync } = useMutation(http.useAddPrepaidPayment, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}

export function useLocationAdminUsers(searchParams: ISearchParams) {
  return transformQueryResponseData<GenericSearchResult<User>>(
    useQuery([queries.locationAdminUsers, searchParams], () =>
      http.searchLocationAdminUsers(searchParams),
    ),
  )
}

export function useCreateLocationUser() {
  const { mutateAsync } = useMutation(http.createLocationUser, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}

export function useEditLocationUser() {
  const { mutateAsync } = useMutation(http.editLocationUser, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}

export function useDeleteLocationUser() {
  const { mutateAsync } = useMutation(http.deleteLocationUser, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}

export function useUpdateLocationPaymentInfo() {
  const { mutateAsync } = useMutation(http.updateLocationPaymentInfo, {
    onError: (error) => {
      throw error
    },
  })
  return mutateAsync
}

// TODO - fetching all right now -> will be better to show only those that can be selected
export function useGetAllPedestals() {
  return useQuery<Pedestal[], AxiosError>(queries.allPedestals, () =>
    http.getAllPedestals(),
  )
}

// TODO - fetching all right now -> will be better to show only those that can be selected
export function useGetAllPedestalTypes() {
  return useQuery<PedestalType[], AxiosError>(queries.allPedestalTypes, () =>
    http.getAllPedestalTypes(),
  )
}

export function useGetAllSourcesByPedestalId(pedestalId?: number) {
  return useQuery<Source[], AxiosError>(queries.allSourcesByPedestalId, () =>
    http.getAllSourcesByPedestalId(pedestalId),
  )
}

export function useGetAllUsers() {
  return useQuery<User[], AxiosError>(queries.allUsers, () =>
    http.getAllUsers(),
  )
}

export function useGetAllVehicles() {
  return useQuery<Vehicle[], AxiosError>(queries.allVehicles, () =>
    http.getAllVehicles(),
  )
}

export function useSourcesReport(searchParams: ISearchParams) {
  return transformQueryResponseData<GenericSearchResult<SocketReport>>(
    useQuery([queries.receipts, searchParams], () =>
      http.searchSourcesReport(searchParams),
    ),
  )
}

export function useReport<TParams extends ReportSearchParams>(
  reportType: ReportType,
  params: TParams,
) {
  return useQuery<Report, AxiosError>([`${reportType}-report`], () =>
    http.searchReport(reportType, params),
  )
}

export function useReportTable<TParams extends ReportSearchParams>(
  reportType: ReportType,
  params: TParams,
) {
  return useQuery<ReportConsumptionTableData, AxiosError>(
    [`${reportType}-report-table`],
    () => http.getReportTable(reportType, params),
  )
}

export function useAdminTransactions(searchParams: ISearchParams) {
  return transformQueryResponseData<GenericSearchResult<Transaction>>(
    useQuery([queries.adminTransactions, searchParams], () =>
      http.getAdminTransactions(searchParams),
    ),
  )
}
